
import Vue from "vue";
import {mapGetters} from 'vuex';
import { UserProfile } from '@/store/types';

export default Vue.extend({
  name: "UserProfile",

  data() {
    return {
      user: {
        avatar: "",
        name: "",
        email: "",
        phone: "",
        active: true,
        uid: "",
      } as UserProfile,
      loading: false as boolean
    };
  },

  computed: {
    ...mapGetters({
      loggedUser: "auth/getUser"
    })
  },

  mounted() {
    if (this.loggedUser) {
      Object.assign(this.user, this.loggedUser)
    }
  },

  methods: {
    async doLogin(): Promise<void> {
      // this.loading = true;
      //
      // await this.$store.dispatch("auth/login", this.user);
      //
      // this.loading = false;
    }
  }
});
